
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

import Listproduk from "@/components/1crm/product/daftar-produk/list-produk.vue";
// import UploadComponent from "@/components/1crm/legal/legalitas/superadmin/upload-modal.vue";

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Listproduk,
    // UploadComponent,
  },
  props: {
    widgetClasses: String,
  },

  beforeMount() {
    store.dispatch(Actions.VERIFY_AUTH);

    setCurrentPageBreadcrumbs("Data Produk", ["Data"]);
  },
});
